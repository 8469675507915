import type { ErrorIdent } from '~/@types/errorIdents';
import { useFavLists } from '~/stores/useFavLists';
import { useDialogStore } from '~/stores/useDialogStore';
import type { FavoriteList } from '~/server/transformers/shop/favoriteListTransformer';

const favListStore = useFavLists();

export function useMultiSelectMoveItems() {
  const errors = ref<ErrorIdent[]>([]);
  const isLoading = ref(false);
  const dialogStore = useDialogStore();

  async function onSubmit(targetListId: string) {
    isLoading.value = true;
    await moveItemsToList(targetListId);
    isLoading.value = false;
  }

  async function moveItemsToList(targetListId: string) {
    try {
      isLoading.value = true;
      const result = await favListStore.moveSelectedItems(targetListId);
      if (result) dialogStore.closeDialog();
    } catch (e: any) {
      errors.value = e.data.data.errors;
      isLoading.value = false;
    }
  }

  return {
    onSubmit,
    errors,
    isLoading,
  };
}

/**
 * need activeFavList on first position, then myFavorites and after that all other lists
 * @returns allFavLists in right order
 */
export function sortFavListsForMovingItems(): FavoriteList[] {
  const allFavLists = [favListStore.getActiveFavList()];
  const myFavoriteList = favListStore.myFavoriteList;

  if (allFavLists[0].id !== myFavoriteList.id) {
    allFavLists.push(myFavoriteList);
  }

  //get all favLists except the activeFavList
  favListStore.favoriteLists.forEach((list) => {
    if (list.id !== allFavLists[0].id) {
      allFavLists.push(list);
    }
  });

  return allFavLists;
}
