<template>
  <Dialog width-class="md:max-w-[510px]">
    <div class="px-sm py-md md:p-md">
      <div class="overflow-auto h-fit">
        <div
          v-if="errors.length && errors.includes(ErrorIdent.UNKNOWN_ERROR)"
          class="mb-md"
        >
          <UnknownError />
        </div>

        <p
          class="font-text text-primary-base min-h-[36px] cursor-pointer text-right"
          @click="
            () => {
              dialogStore.openDialog(DialogIdent.NEW_EDIT_FAVORITE_LIST, {
                options: {
                  preventNavigateTo: true,
                  comingFromMultiselect: true,
                },
              });
            }
          "
        >
          <FaIcon icon-class="far fa-plus" />
          {{ t('myProducts.sideNav.addNewList') }}
        </p>

        <div
          v-for="(favList, index) in favLists"
          :key="index"
          class="flex justify-between cursor-pointer rounded-alt-md p-sm"
          :class="[
            index > 0 ? 'mt-xs' : 'mt-0',
            selectedList === favList.id
              ? 'bg-primary-lightest '
              : 'bg-background-base',
          ]"
          @click="updateSelectedList(favList.id)"
        >
          <div class="flex">
            <div
              class="flex justify-center items-center pl-[6px] pt-[6px] w-xl h-xl mr-sm bg-[url('@/assets/images/white-frames.svg')]"
            >
              <img
                v-if="
                  $siteIdent.startsWith('minilu') && favList.items.length == 0
                "
                class="max-w-full max-h-full p-2xs"
                src="@/assets/images/minilu/pink-package-icon.svg"
              />
              <img
                v-else-if="
                  !$siteIdent.startsWith('minilu') && favList.items.length == 0
                "
                class="max-w-full max-h-full p-2xs"
                src="@/assets/images/vdv/green-package-icon.svg"
              />
              <ProductImage
                v-else
                :image-url="favList.items[0]?.product?.media?.cover"
                :alt-text="favList.items[0]?.product?.media?.alt"
                class="max-w-full max-h-full p-2xs"
              />
            </div>
            <div class="flex flex-col">
              <p class="font-bold">{{ t(favList.name) }}</p>
              <p>
                {{ favList.items?.length }}
                {{ t('shop.cart.general.article') }}
              </p>
            </div>
          </div>
          <div>
            <RadioButton
              name="favLists"
              :model-value="selectedList"
              :options="[{ label: '', value: favList.id }]"
              @click="updateSelectedList(favList.id)"
            >
            </RadioButton>
          </div>
        </div>

        <ButtonBasic
          classes="w-full px-sm md:px-0 mt-md"
          :label="t('favLists.multiSelect.moveItems')"
          :disabled="isLoading"
          full-width
          @click="onSubmit(selectedList)"
        ></ButtonBasic>
        <div
          class="flex items-center justify-center w-full gap-2 cursor-pointer text-primary-base mt-md"
          @click="dialogStore.closeDialog()"
        >
          <span class="anim__underline-link anim-link">
            {{ t('formFields.form.cancel') }}
          </span>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { useDialogStore, DialogIdent } from '~/stores/useDialogStore';
import { ErrorIdent } from '~/@types/errorIdents';
import ButtonBasic from '~~/src/components/components/core/basic-button/basic-button.vue';
import FaIcon from '@/components/fa-icon.vue';
import { RadioButton, ProductImage } from '@/complib';
import {
  useMultiSelectMoveItems,
  sortFavListsForMovingItems,
} from './useMultiSelectMoveItems';
import { useFavLists } from '~/stores/useFavLists';
import type { FavoriteList } from '~/server/transformers/shop/favoriteListTransformer';

const favListStore = useFavLists();
const dialogStore = useDialogStore();
await favListStore.loadLists(false);

const { t } = useTrans();

const favLists = ref<FavoriteList[]>();
const selectedList = ref<string>('');

onMounted(() => {
  favLists.value = sortFavListsForMovingItems();

  //get id of first favList a.k.a. activeFavList
  selectedList.value = favLists.value[0].id;
});

watch(
  [favLists, favListStore.initialized, dialogStore.open, selectedList],
  async () => {
    const products = await favListStore.loadProductsInList();

    products.forEach((product) => {
      // check each favList.items for the productID and map the product if found
      favLists.value.forEach((favList) => {
        const favListItem = favList.items.find(
          (item) => item.productId === product.id,
        );
        if (!favListItem) return;
        favListItem.product = product;
      });
    });
  },
);

function updateSelectedList(listId: string) {
  selectedList.value = listId;
}

const { onSubmit, errors, isLoading } = useMultiSelectMoveItems();
</script>
